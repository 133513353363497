<template>
    <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="服务号列表" name="first">
            <WaiterServicesDetail v-if="activeName === 'first'"></WaiterServicesDetail>
        </el-tab-pane>
        <el-tab-pane label="服务号分组" name="second">
            <WaiterServicesCrowd v-if="activeName === 'second'"></WaiterServicesCrowd>
        </el-tab-pane>
    </el-tabs>
</template>

<script>
import WaiterServicesCrowd from "@/views/waiter/detail/WaiterServicesCrowd.vue"
import WaiterServicesDetail from "@/views/waiter/detail/WaiterServicesDetail.vue"


export default {
    name: "WaiterServices",
    components: {
        WaiterServicesCrowd,
        WaiterServicesDetail
    },
    data() {
        return {
            activeName: 'first',
        }
    },
    mounted() {

    },
    methods: {
        handleClick() {

        },
    }
}
</script>

<style scoped>
.el-pagination {
    bottom: 10px;
    position: fixed;
}
</style>